import { FaUser } from 'react-icons/fa';
import './styles.scss';

import UploadFile from './UploadFile';
import { useContext, useEffect } from 'react';
import { FinalRelationshipContext } from '../../../context/Context';
import Checked from './Checked';
import ReactInputMask from 'react-input-mask';
import { FaCalendarDays } from 'react-icons/fa6';

export default function Header() {
    const { name, setName, jsonName, checkedEnt, setCheckedEnt, checkedSimp, setCheckedSimp, setProcessedData, dataPriority, setDataPriority, simpVariable, setSimpVariable, checkedBrave, setCheckedBrave } = useContext(FinalRelationshipContext);

    const handleNameChange = (event) => {
        const value = event.target.value;
        if (/^[A-Za-z\s]*$/.test(value)) {
            setName(value);
        }
    };

    const handleInputSimp = (event) => {
        const value = event.target.value;

        setSimpVariable(value);
    };

    const handleDataPriorityChange = (event) => {
        const value = event.target.value;

        setDataPriority(value);
    };

    const suggestCompleteName = () => {
        const nameToSuggest = jsonName.find(savedName =>
            savedName.toLowerCase().startsWith(name.toLowerCase())
        );
        if (nameToSuggest) {
            setName(nameToSuggest);
        }
    };

    return (
        <header id='Header'>
            <div className='configs'>
                <div className="title">
                    <span>Relatório Peças</span>
                </div>
                <div className="name">
                    <div className="text">
                        <span>NOME</span>
                    </div>
                    <div className="input">
                        <FaUser />
                        <input
                            type="text"
                            placeholder="Digite seu nome"
                            value={name}
                            onChange={handleNameChange}
                            onBlur={suggestCompleteName} // Sugere nome ao sair do campo
                        />
                    </div>
                </div>
                <div className="select">
                    <span>Simplificar</span>
                    <Checked checked={checkedSimp} setChecked={setCheckedSimp} />
                </div>
                <div className={`inputSimp inputSimp${checkedSimp}`}>
                    <ReactInputMask
                        mask="999"
                        maskChar=''
                        placeholder="Cafs Ignoradas"
                        value={simpVariable}
                        onChange={handleInputSimp}
                    />
                </div>
                <div className="select">
                    <span>Incluir Saida</span>
                    <Checked checked={checkedBrave} setChecked={setCheckedBrave} />
                </div>
                <div className="select">
                    <span>Incluir Entregado</span>
                    <Checked checked={checkedEnt} setChecked={setCheckedEnt} />
                </div>
                <div className="date">
                    <div className="text">
                        <span>Data Prioridade</span>
                    </div>
                    <div className="input">
                        <FaCalendarDays />
                        <ReactInputMask
                            mask="99/99/9999"
                            maskChar='_'
                            placeholder="Cafs Ignoradas"
                            value={dataPriority}
                            onChange={handleDataPriorityChange}
                        />
                    </div>
                </div>
                <div className="inffoSelect">
                    <span>AWB | Rota | ID da caf |  Descrição do status | Data de Promessa da Entrega | Nome do Motorista</span>
                </div>
            </div>
            <div className="upload">
                <UploadFile setProcessedData={setProcessedData} />
            </div>
        </header>
    )
}