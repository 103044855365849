import { useContext, useState } from "react";
import { ReportPartsContext } from "../../../context/Context";

function formatDate(dateString) {
    const parts = dateString.split('/');
    if (parts.length !== 3) return null; // Verifica se a data está no formato correto

    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Meses são indexados de 0 a 11
    const year = parseInt(parts[2], 10);

    const date = new Date(year, month, day);
    if (isNaN(date.getTime())) {
        return null; // Retorna null se a data não for válida
    }

    const months = [
        "janeiro", "fevereiro", "março", "abril", "maio", "junho", 
        "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"
    ];

    const formattedDay = date.getDate();
    const formattedMonth = months[date.getMonth()];
    const formattedYear = date.getFullYear();

    return `${formattedDay} de ${formattedMonth} de ${formattedYear}`;
}

function formatDatePriority(date) {
    
    // Cria um objeto Date a partir da string
    
    // Verifica se a data é válida
    if (isNaN(date.getTime())) {
        return null; // Retorna null se a data não for válida
    }

    // Obtém o dia e o mês
    const day = String(date.getDate()).padStart(2, '0'); // Preenche com zero à esquerda se necessário
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês é baseado em zero, então adiciona 1

    // Retorna a data formatada
    return `${day}/${month}`;
}

function formatHighVolumeRoutes(routes) {
    const routesArray = routes.split(' | ');
    const groupedRoutes = [];

    for (let i = 0; i < routesArray.length; i += 3) {
        // Junte os grupos com o caractere de crase no final
        groupedRoutes.push('`' + routesArray.slice(i, i + 3).join(' | ') + '`');
    }

    return groupedRoutes.join('\n'); // Junte os grupos com quebras de linha
}

function Message({ typeItem, processedDataResult, name }) {
    const [copiadoVisivel, setCopiadoVisivel] = useState(false);
    const { PAR } = useContext(ReportPartsContext);
    
    const today = new Date();

    const totalAmount = processedDataResult.reduce((accumulator, item) => accumulator + item.amount, 0);

    // Filtra rotas com amount maior que 120
    const highVolumeRoutes = processedDataResult
        .filter(item => item.amount > 120)
        .map(item => `${item.router} ${item.amount}`)
        .join(' | ');

    // Formata as rotas de alto volume
    const formattedHighVolumeRoutes = formatHighVolumeRoutes(highVolumeRoutes);

    // Filtra prioridades de saída onde isPrioritized é true
    const prioritizedOutputs = processedDataResult
        .filter(item => item.isPrioritized)
        .map(item => `\`${item.router} | ${item.priority} Peças ( ${item.date} )\``)
        .join('\n');

    const handleCopy = () => {
        const textoParaCopiar = document.querySelector('.itenCopy').innerText; // Usar innerText para preservar formatação
        navigator.clipboard.writeText(textoParaCopiar)
            .then(() => {
                setCopiadoVisivel(true);
                setTimeout(() => {
                    setCopiadoVisivel(false);
                }, 1000); // 1 segundo
            })
            .catch((err) => {
                console.error('Erro ao copiar texto: ', err);
            });
    };
    
    return (
        <div onClick={handleCopy}>
            {copiadoVisivel && (
                <div className="copy">
                    <span>Copiado!</span>
                </div>
            )}
            <pre className="itenCopy">
                {`Relatório de ${PAR ? "P. A. R. Recebidos" : "Peças Recebidas"}.
${PAR ? "" : `*${typeItem.length === 0 ? '' :
    typeItem.length === 1 ? typeItem[0] :
        typeItem.slice(0, -1).join(' | ') + ' | ' + typeItem[typeItem.length - 1]}*`}

${PAR ? `Hoje *${formatDatePriority(today)}* temos um total de *${totalAmount}* P. A. R. para expedir.` : `Hoje, *${formatDatePriority(today)}*, temos a previsão de receber *${totalAmount}* peças 
(mais as INJ).`}
${formattedHighVolumeRoutes && (
    `${PAR ? "" : `\nRotas com *maior* volume:\n${formattedHighVolumeRoutes}\n`}`
)}
${prioritizedOutputs && (
    `*Prioridades de saída*:\n${prioritizedOutputs}\n`
)}
@MARCAR

~${name}`}
            </pre>
        </div>
    );
}

export default Message;