import { createContext, useEffect } from "react";
import React, { useState } from 'react';

const Context = createContext();
const ReportPartsContext = createContext();
const FinalRelationshipContext = createContext();

const UserProvider = ({ children }) => {
    return (
        <Context.Provider value={{}}>
            {children}
        </Context.Provider>
    );
};

const ReportPartsProvider = ({ children }) => {

    const [processedData, setProcessedData] = useState([]);
    const [processedDataResult, setProcessedDataResult] = useState([]);
    const [checked, setChecked] = useState(false);
    const [PAR, setPAR] = useState(false);
    const [dataPriority, setDataPriority] = useState('');
    const [name, setName] = useState('');
    const [openConfig, setOpenConfig] = useState(false);

    const [jsonName, setJsonName] = useState([]);
    const [jsonRouter, setJsonRouter] = useState([]);

    return (
        <ReportPartsContext.Provider value={{ name, setName, processedData, setProcessedData, PAR, setPAR, checked, setChecked, dataPriority, setDataPriority, processedDataResult, setProcessedDataResult, openConfig, setOpenConfig, jsonName, setJsonName, jsonRouter, setJsonRouter }}>
            {children}
        </ReportPartsContext.Provider>
    );
};





const FinalRelationshipProvider = ({ children }) => {
    function formateDate() {
        const today = new Date();
    
        // Obtenha o dia, mês e ano
        const day = String(today.getDate()).padStart(2, '0'); // Garante dois dígitos
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Janeiro é 0
        const year = today.getFullYear();
    
        // Formate a data no formato dd/mm/yyyy
        return `${day}/${month}/${year}`;
    }

    const [processedData, setProcessedData] = useState([]);
    const [name, setName] = useState('');
    const [jsonName, setJsonName] = useState([]);
    const [dataPriority, setDataPriority] = useState(`${formateDate()}`);
    const [dataPriorityMs, setDataPriorityMs] = useState(`${new Date().setHours(0, 0, 0, 0)}`);
    const [checkedEnt, setCheckedEnt] = useState(false);
    const [checkedSimp, setCheckedSimp] = useState(false);
    const [checkedBrave, setCheckedBrave] = useState(true);
    const [selectedItems, setSelectedItems] = useState([]);
    
    const [simpVariable, setSimpVariable] = useState(0);
    const [objData, setObjData] = useState([]);
    const [selectedKey, setSelectedKey] = useState('');

    return (
        <FinalRelationshipContext.Provider value={{ name, setName, jsonName, setJsonName, checkedEnt, setCheckedEnt, checkedSimp, setCheckedSimp, processedData, setProcessedData, selectedKey, setSelectedKey, objData, setObjData, dataPriority, setDataPriority, dataPriorityMs, setDataPriorityMs, simpVariable, setSimpVariable, checkedBrave, setCheckedBrave, selectedItems, setSelectedItems }}>
            {children}
        </FinalRelationshipContext.Provider>
    );
};

export { Context, UserProvider, ReportPartsContext, ReportPartsProvider, FinalRelationshipContext, FinalRelationshipProvider };
