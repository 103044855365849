import Header from "../Header";
import Main from "../Main";
import JsonName from "./JsonName";
import './styles.scss';

export default function Home(){
    JsonName();
    return (
        <div id="FinalRelationship">
            <Header />
            <Main />
        </ div>
    )
}