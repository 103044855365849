import { useContext, useEffect, useRef, useState } from "react";
import { FinalRelationshipContext } from "../../../context/Context";

export default function SelectDriver({data, setData}) {
    const { setSelectedKey, objData } = useContext(FinalRelationshipContext);

    const [options, setOptions] = useState([]);
    
    useEffect(() => {

        setOptions(data.map(item => ({
            key: item.ID,
            label: `${item.Rota} - ${item.Nome} (${item.ID})`
        })))  
        setInputValue('');
        setSelectedKey('');

    }, [data, objData])
    
    

    const [filteredOptions, setFilteredOptions] = useState(options);
    const [inputValue, setInputValue] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const optionClickedRef = useRef(false);

    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);

        // Limpa a key selecionada se o campo de entrada estiver vazio
        if (value === '') {
            setSelectedKey('');
        }

        // Filtrar opções por texto ou chave
        const filtered = options.filter(
            (option) =>
                option.label.toLowerCase().includes(value.toLowerCase()) ||
                option.key.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredOptions(filtered);
        setIsDropdownOpen(true);
    };

    const handleOptionClick = (option) => {
        optionClickedRef.current = true; // Marca que uma opção foi clicada
        setInputValue(option.label); // Define o valor do input como o label da opção
        setSelectedKey(option.key); // Armazena a key da opção selecionada
        setIsDropdownOpen(false);
    };

    const handleFocus = () => {
        setFilteredOptions(options); // Mostrar todas as opções ao focar
        setIsDropdownOpen(true);
    };

    const handleBlur = () => {
        setTimeout(() => {
            if (!optionClickedRef.current) {
                // Se nenhuma opção foi clicada, limpa o campo se não for válido
                const isInputValid = options.some(
                    (option) => option.label === inputValue || option.key === inputValue
                );
                if (!isInputValid) {
                    setInputValue('');
                    setSelectedKey(''); // Limpa a key também se o valor não for válido
                }
            }
            optionClickedRef.current = false; // Reseta o estado
            setIsDropdownOpen(false);
        }, 200);
    };


    return (
        <div className="custom-select">
            <input
                type="text"
                placeholder="Escolher"
                value={inputValue}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className="custom-input"
            />
            {isDropdownOpen && (
                <ul className="dropdown">
                    {filteredOptions.length > 0 ? (
                        filteredOptions.map((option, index) => (
                            <li
                                key={option.key}
                                onClick={() => handleOptionClick(option)}
                                className="dropdown-item"
                            >
                                {`${option.label}`}
                            </li>
                        ))
                    ) : (
                        <li className="dropdown-item">Nenhuma opção</li>
                    )}
                </ul>
            )}
        </div>
    )
}