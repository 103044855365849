
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import { FinalRelationshipProvider, UserProvider } from "./context/Context"
import ReportParts from "./page/ReportParts/Application";
import FinalRelationship from "./page/FinalRelationship/Application";

import { ReportPartsProvider } from "./context/Context";
import Home from "./page/Home";


function App() {
  return (
    <Router>
      <UserProvider>
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/ReportParts" element={
            <ReportPartsProvider>
              <ReportParts />
            </ReportPartsProvider>
          } exact />

          {/* <Route path="/Planning" element={
            <PlanningProvider>
              <Planning />
            </PlanningProvider>
          } exact /> */}

          <Route path="/FinalRelationship" element={
            <FinalRelationshipProvider>
              <FinalRelationship />
            </FinalRelationshipProvider>
          } exact />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </UserProvider>
    </Router>
  );
}

export default App;
