import { useContext, useEffect, useState } from "react";
import { FinalRelationshipContext } from "../../../context/Context";

function formatDatePriority(date) {

    // Cria um objeto Date a partir da string

    // Verifica se a data é válida
    if (isNaN(date.getTime())) {
        return null; // Retorna null se a data não for válida
    }

    // Obtém o dia e o mês
    const day = String(date.getDate()).padStart(2, '0'); // Preenche com zero à esquerda se necessário
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês é baseado em zero, então adiciona 1

    // Retorna a data formatada
    return `${day}/${month}`;
}

function calculateMetrics(data, todayMs) {
    if (!data || !Array.isArray(data)) return { madeCAF: 0, issuedCAF: 0, priorities: { counterCAF: 0, counter: 0 } };

    let madeCAF = 0;
    let issuedCAF = 0;
    let counter = 0; // Quantidade total de peças prioritárias
    let counterCAF = 0; // Quantidade de CAFs com prioridade de saída

    data.forEach((item) => {
        // Cálculo para madeCAF
        if (!item.IDs || item.IDs.length === 0) {
            madeCAF++; // Conta como 1 se não houver IDs
        } else {
            madeCAF += item.IDs.length; // Soma a quantidade de IDs
        }

        // Cálculo para issuedCAF
        if (["inStreet", "justLeft"].includes(item.description)) {
            if (item.IDs && Array.isArray(item.IDs)) {
                issuedCAF += item.IDs.length; // Soma a quantidade de IDs
            } else {
                issuedCAF++; // Conta como 1 se não houver IDs
            }
        }

        // Cálculo para priorities
        if (item.Data && Array.isArray(item.Data)) {
            const timestamps = item.Data.map((date) => {
                const [day, month, year] = date.split("/");
                const newDate = new Date(year, month - 1, day);
                newDate.setHours(0, 0, 0, 0);
                return newDate.getTime();
            });

            const matchesToday = timestamps.filter((timestamp) => timestamp === todayMs).length;

            counter += matchesToday; // Soma o total de peças prioritárias para hoje
            if (matchesToday > 0) counterCAF++; // Incrementa se há pelo menos uma peça prioritária
        }
    });

    return {
        madeCAF,
        issuedCAF,
        priorities: { counterCAF, counter },
    };
}



function Message({ name }) {
    const [copiadoVisivel, setCopiadoVisivel] = useState(false);

    const [volumes, setvolumes] = useState(0);
    const [madeCAF, setMadeCAF] = useState(0);
    const [issuedCAF, setIssuedCAF] = useState(0);
    const [priorities, setPriorities] = useState(0);
    const [dataPriorityMs, setDataPriorityMs] = useState();

    const { objData, dataPriority } = useContext(FinalRelationshipContext);

    const setDate = (dataPriority) => {
        if (dataPriority) {
            try {
                const [day, month, year] = dataPriority.split("/").map(Number);

                if (!day || !month || !year) {
                    return new Date().setHours(0, 0, 0, 0);
                }

                const date = new Date(year, month - 1, day);
                if (isNaN(date.getTime())) {
                    return new Date().setHours(0, 0, 0, 0);
                }

                return date.setHours(0, 0, 0, 0);
            } catch (error) {
                return new Date().setHours(0, 0, 0, 0);
            }
        } else {
            return new Date().setHours(0, 0, 0, 0);
        }
    };

    useEffect(() => {
        setDataPriorityMs(setDate(dataPriority))
    }, [dataPriority])

    useEffect(() => {
        if (!objData || !Array.isArray(objData)) return;

        const { madeCAF, issuedCAF, priorities } = calculateMetrics(objData, dataPriorityMs);

        // Soma total dos AWBs (soma dos tamanhos dos arrays em "AWB")
        const awbCount = objData.reduce((sum, item) => sum + item.AWB.length, 0);

        
        // Atualizando os estados
        setPriorities(priorities)
        setMadeCAF(madeCAF);
        setvolumes(awbCount);
        setIssuedCAF(issuedCAF);
    }, [objData, dataPriorityMs]);

    const today = new Date();

    const handleCopy = () => {
        const textoParaCopiar = document.querySelector('.itenCopy').innerText; // Usar innerText para preservar formatação
        navigator.clipboard.writeText(textoParaCopiar)
            .then(() => {
                setCopiadoVisivel(true);
                setTimeout(() => {
                    setCopiadoVisivel(false);
                }, 1000); // 1 segundo
            })
            .catch((err) => {
                console.error('Erro ao copiar texto: ', err);
            });
    };

    return (
        <div onClick={handleCopy}>
            {copiadoVisivel && (
                <div className="copy">
                    <span>Copiado!</span>
                </div>
            )}
            <pre className="itenCopy">
                {`Bom-dia! 🌞

📊 *CAF's Feitas, Auditadas e Expedidas - 3° Turno*

Hoje *${formatDatePriority(today)}*

  • Foram *${madeCAF}* CAF's feitas, movimentando *${volumes}* peças para serem expedidas.
  • *${priorities.counterCAF}* CAF's com prioridade de saída, somando *${priorities.counter}* peças prioritárias do dia *${dataPriority}*.
  • Já foram expedidas *${issuedCAF}* CAF's.

💡 Fiquem atentos às prioridades! 💡

@MARCAR

~${name}
`}
            </pre>
        </div>
    );
}

export default Message;