import { useContext, useEffect, useState } from "react";
import { FinalRelationshipContext } from "../../../context/Context";
import { FaCalendarDays } from "react-icons/fa6";
import ReactInputMask from "react-input-mask";
import { FaWindowClose, FaUser } from "react-icons/fa";

export default function EditBox({ click, setClick, editItem }) {
    const { objData, selectedKey, setObjData } = useContext(FinalRelationshipContext);
    const [name, setName] = useState(""); 
    const [selectedDescription, setSelectedDescription] = useState(""); 

    // Obtém o item selecionado
    const itenSelected = objData.filter((item) => item.ID === selectedKey)[0];

    useEffect(() => {
        function handleClickOutside(event) {
            if (click) {
                if (!event.target.closest(`.itemEdit`)) {
                    editItem();
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [editItem, click]);

    useEffect(() => {
        if (!selectedKey || !itenSelected) return;

        setName(itenSelected.Nome);
        setSelectedDescription(itenSelected.description); 
    }, [itenSelected, selectedKey]);

    const handleNameChange = (event) => {
        const value = event.target.value;
        setName(value);
    };

    const handleDescriptionChange = (event) => {
        const value = event.target.value;
        setSelectedDescription(value);
    };

    const descriptionMap = {
        inCAF: "Em CAF",
        justLeft: "Na Rua",
        inStreet: "Entregando",
        default: "Indefinido",
    };

    const clickSave = () => {
        if (!itenSelected) return;

        // Atualiza o objData no contexto
        const updatedData = objData.map((item) => 
            item.ID === selectedKey
                ? { ...item, Nome: name, description: selectedDescription }
                : item
        );

        setObjData(updatedData); // Atualiza no contexto
        editItem(); // Fecha o modal após salvar
    };

    const descriptionOptions = Object.entries(descriptionMap).map(([key, value]) => ({
        key,
        value,
    }));

    // Caso não encontre a descrição, define o valor como 'default'
    const currentDescription = descriptionMap[itenSelected?.description] || descriptionMap['default'];

    if (!selectedKey || !itenSelected) return null;

    return (
        <div className={`editBox editBox-${click}`}>
            <div className="itemEdit">
                <div className="icon" onClick={editItem}>
                    <FaWindowClose />
                </div>
                <div className="title">
                    <span>Editar Caf {itenSelected?.ID}</span>
                </div>
                <div className="nameCAF">
                    <FaUser />
                    <ReactInputMask
                        placeholder="Nome"
                        value={name}
                        onChange={handleNameChange}
                    />
                </div>

                <div className="dataLoading">
                    <select onChange={handleDescriptionChange} value={selectedDescription}>
                        {/* Exibe o valor atual do description, se encontrado */}
                        <option value={itenSelected?.description}>
                            {currentDescription}
                        </option>
                        {/* Exibe as opções do descriptionMap, removendo a descrição já selecionada */}
                        {descriptionOptions
                            .filter((option) => option.key !== itenSelected?.description)
                            .map((option) => (
                                <option key={option.key} value={option.key}>
                                    {option.value}
                                </option>
                            ))}
                    </select>
                </div>
                <div className="buttonDn">
                    <button onClick={clickSave}>SALVAR</button>
                </div>
            </div>
        </div>
    );
}
