import { useContext, useEffect, useRef, useState } from 'react';
import Message from './Message';
import './styles.scss';
import { FinalRelationshipContext } from '../../../context/Context';
import SelectDriver from './SelectDriver';
import CreateList from './CreateList';
import FooterSheet from './FooterSheet.js';
import { captureDiv } from './CaptureDiv.js';
import EditBox from './EditBox.js';

export default function Main() {
    const { name, objData, setObjData, selectedKey, selectedItems, setSelectedItems } = useContext(FinalRelationshipContext);
    const [click, setClick] = useState(false);

    function editItem(){
        click ? setClick(false) : setClick(true)
    }

    const currentDate = new Date();  // Cria a data atual

    function removeItem(){
        
        if (selectedKey !== null && selectedKey !== "" && selectedKey !== undefined) {
            const filteredData = objData.filter(item => item.ID !== selectedKey);
            setObjData(filteredData)
        }else {
            const filteredData = objData.filter(item => !selectedItems.includes(item.ID));
            setObjData(filteredData);
            setSelectedItems([]); // Limpa a seleção
        }
        
    }
   

    // Formata a data no formato DD/MM
    const formattedDate = currentDate.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
    });

    return (
        <main id="Main">
            <div className="edit">
                <SelectDriver data={objData} setData={setObjData} />
                <div className="button">
                    <button type="submit" onClick={editItem} disabled={selectedKey !== null && selectedKey !== "" && selectedKey !== undefined ? false : true}>Editar</button>
                    <button type="submit" onClick={removeItem}>Apagar</button>
                </div>
            </div>
            <EditBox click={click} setClick={setClick} editItem={editItem}/>
            <div className="message">
                <Message name={name} />
            </div>

            <div className="graphic">
                <div className="buttonDn">
                    <button onClick={captureDiv}>Baixar</button>
                </div>
                <div className="sheet captureDiv">
                    <div className="herderSheet">
                        <div className="logo">
                            <div className="bgImage">

                            </div>
                        </div>
                        <div className="title">
                            <span>RELATÓRIO DE CONTROLE</span>
                            <span>TERCEIRO TURNO</span>
                        </div>
                        <div className="date">
                            <span>{formattedDate}</span>
                        </div>
                        <div className="logoProperty">
                        </div>
                    </div>
                    <CreateList />
                    <FooterSheet />
                </div>

            </div>

        </main>
    );
}
