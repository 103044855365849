import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaFileUpload } from "react-icons/fa";
import { LuUpload } from "react-icons/lu";
import * as XLSX from 'xlsx';
import JSZip from 'jszip';
import CreateList from "../Main/CreateList";

function UploadFile({ setProcessedData }) {
    const [dragActive, setDragActive] = useState({ data: false, dataSec: false });
    const [uploadedFiles, setUploadedFiles] = useState(new Set());
    const [fileUploadedStatus, setFileUploadedStatus] = useState({ data: false, dataSec: false });
    const [error, setError] = useState(false);

    useEffect(() => {
        setProcessedData([]);
        setFileUploadedStatus({ data: false, dataSec: false });
        setUploadedFiles(new Set());
        setDragActive({ data: false, dataSec: false });
        setError(false);
    }, [error, setProcessedData]);

    const parseHtmlToObject = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const rows = doc.querySelectorAll('tr');
        const dataArray = [];

        rows.forEach(row => {
            const cells = row.querySelectorAll('td');
            
            const rowData = [];
            cells.forEach(cell => {         
                rowData.push(cell.textContent.trim());
            });
            
            dataArray.push(rowData);
        });    

        try {
            const headers = dataArray[0].map(header => header.split(" ")[0]);
            const result = dataArray.slice(1).map(row => {
                
                return {
                    [headers[0]]: row[0],
                    [headers[1]]: row[1],
                    [headers[2]]: row[2],
                    [headers[3]]: row[3],
                    [headers[4]]: row[4],
                    [headers[5]]: row[5]
                };
            });
            
            setError(false);
            return result;
        } catch {
            setError(true);
            return null;
        }
    };

    const handleZipFile = async (file, fileType) => {
        const zip = new JSZip();
        try {
            const content = await zip.loadAsync(file);
            const files = Object.keys(content.files);
            // Supondo que você sempre deseja processar o primeiro arquivo .xlsx encontrado no .zip
            const xlsxFile = files.find(name => name.endsWith('.xlsx') || name.endsWith('.xls'));
            if (xlsxFile) {
                const extractedFile = await content.files[xlsxFile].async('arraybuffer');
                processExcelFile(new Uint8Array(extractedFile), fileType);
            } else {
                setError(true);
                throw new Error("No .xlsx/.xls file found in the zip.");
            }
        } catch (error) {
            setError(true);
            console.error(error);
        }
    };

    const processExcelFile = (data, fileType) => {
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const dataJson = json[json.length - 1][4];
        
        const result = parseHtmlToObject(dataJson);
        
        if (!result) {
            throw new Error("Parsing error");
        }

        setProcessedData(prevData => [...prevData, { type: fileType, data: result }]);
        setUploadedFiles(prev => new Set(prev).add(fileType)); // Track uploaded file types
        setFileUploadedStatus(prev => ({ ...prev, [fileType]: true })); // Atualiza status de upload
    };

    const handleDrop = (file, fileType) => {
        if (uploadedFiles.has(fileType) || error) {
            return;
        }

        const fileExtension = file.name.split('.').pop().toLowerCase();
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);

            if (fileExtension === 'zip') {
                handleZipFile(data, fileType); // Processar arquivo zip
            } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                processExcelFile(data, fileType); // Processar arquivo Excel
            } else {
                setError(true); // Se não for .zip ou .xlsx
            }
        };

        reader.readAsArrayBuffer(file);
    };

    const onDrop = useCallback((acceptedFiles, fileType) => {
        acceptedFiles.forEach(file => handleDrop(file, fileType));
    }, [uploadedFiles]);

    const getRootPropsWithCheck = (getRootProps, fileType) => {
        return uploadedFiles.has(fileType)
            ? { className: 'dropzone disabled' }
            : getRootProps();
    };

    const { getRootProps: getRootPropsData, getInputProps: getInputPropsData } = useDropzone({
        onDrop: (files) => onDrop(files, "Dados"),
        onDragEnter: () => setDragActive(prev => ({ ...prev, data: true })),
        onDragLeave: () => setDragActive(prev => ({ ...prev, data: false })),
        onDropAccepted: () => setDragActive(prev => ({ ...prev, data: false })), // Reset on accepted drop
    });

    const { getRootProps: getRootPropsDataSec, getInputProps: getInputPropsSec } = useDropzone({
        onDrop: (files) => onDrop(files, "Complementos"),
        onDragEnter: () => setDragActive(prev => ({ ...prev, dataSec: true })),
        onDragLeave: () => setDragActive(prev => ({ ...prev, dataSec: false })),
        onDropAccepted: () => setDragActive(prev => ({ ...prev, dataSec: false })), // Reset on accepted drop
    });

    return (
        <>
            {["Dados", "Complementos"].map((label, index) => (
                <div className="itenUpload" key={index}>
                    <span>{label}</span>
                    <div
                        {...getRootPropsWithCheck(
                                    label === "Dados" ? getRootPropsData :
                                        getRootPropsDataSec, label)}
                        className={`dropzone ${dragActive[label] ? 'active' : ''} ${uploadedFiles.has(label) ? 'disabled' : ''}`}
                    >
                        <input
                            {...(label === "Dados" ? getInputPropsData() :
                                        getInputPropsSec())}
                            disabled={uploadedFiles.has(label)}
                        />
                        <div className="upload-icon">
                            {fileUploadedStatus[label] || dragActive[label] ? <FaFileUpload /> : <LuUpload />} {/* Altera a lógica do ícone */}
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
}

export default UploadFile;
