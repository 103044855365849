import Switch from 'react-switch';


function Checked({checked, setChecked}) {

    const handleChange = (nextChecked) => {
        setChecked(nextChecked);
    };

    return (
        <Switch
            onChange={handleChange}
            checked={checked}
            onColor="#4CAF50"       // Cor verde quando ligado
            offColor="#CCCCCC"      // Cor cinza quando desligado
            onHandleColor="#FFFFFF" // Cor da bolinha quando ligado
            offHandleColor="#FFFFFF" // Cor da bolinha quando desligado
            handleDiameter={20}     // Diâmetro da bolinha
            uncheckedIcon={false}   // Sem ícone quando desligado
            checkedIcon={false}     // Sem ícone quando ligado
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" // Sombra da bolinha
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)" // Sombra quando clicado
            height={20}             // Altura do switch
            width={40}              // Largura do switch
        />
    )
}


export default Checked