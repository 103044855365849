import { useContext, useEffect, useState } from "react";
import { FinalRelationshipContext } from "../../../context/Context";

const calculatePriority = (Data, dataPriorityMs) => {

    let counter = 0

    Data.map((itens) => {

        if (!itens.Data || itens.Data.length === 0) return
        

        const timestamps = itens.Data.map((date) => {
            const [day, month, year] = date.split("/");
            const newDate = new Date(year, month - 1, day);
            newDate.setHours(0, 0, 0, 0);
            return newDate.getTime();
        });

        counter += timestamps.filter(valor => valor === dataPriorityMs).length            

    })



    return counter
  
};

export default function FooterSheet() {
    const { objData, dataPriority } = useContext(FinalRelationshipContext);
    const [volumes, setvolumes] = useState(0);
    const [madeCAF, setMadeCAF] = useState(0);
    const [issuedCAF, setIssuedCAF] = useState(0);
    const [priorities, setPriorities] = useState(0);

    const [dataPriorityMs, setDataPriorityMs] = useState();
    
   useEffect(() => {
        setDataPriorityMs(setDate(dataPriority))
    }, [dataPriority])
    
    const setDate = (dataPriority) => {
        if (dataPriority) {
            try {
                const [day, month, year] = dataPriority.split("/").map(Number);

                if (!day || !month || !year) {
                    return new Date().setHours(0, 0, 0, 0);
                }

                const date = new Date(year, month - 1, day);
                if (isNaN(date.getTime())) {
                    return new Date().setHours(0, 0, 0, 0);
                }

                return date.setHours(0, 0, 0, 0);
            } catch (error) {
                return new Date().setHours(0, 0, 0, 0);
            }
        } else {
            return new Date().setHours(0, 0, 0, 0);
        }
    };

    function cafCount(){              
        if(objData === undefined) return;
        let cont = 0
        objData.forEach((item) => { // Usar forEach para iterar sobre objData
            if (!item.IDs || item.IDs.length === 0) {
                cont++; // Somar 1 se não houver IDs ou for vazio
            } else {
                cont += item.IDs.length; // Somar a quantidade de itens dentro de IDs
            }
        });
        setMadeCAF(cont);
    }
    function calculateIssuedCAF(data) {
        if (!data || !Array.isArray(data)) return 0; // Verificar se os dados são válidos
    
        let count = 0;
    
        data.forEach((item) => {
            if (["inStreet", "justLeft"].includes(item.description)) {
                if (item.IDs && Array.isArray(item.IDs)) {
                    count += item.IDs.length; // Adiciona a quantidade de IDs se existir
                } else {
                    count += 1; // Se não tiver IDs, conta como 1
                }
            }
        });
    
        return count;
    }
    
    


    useEffect(() => {
        if (!objData || !Array.isArray(objData)) return;
    
        // Total de CAFs (quantidade de objetos no array)
        cafCount();
    
        // Soma total dos AWBs (soma dos tamanhos dos arrays em "AWB")
        const awbCount = objData.reduce((sum, item) => sum + item.AWB.length, 0);
    
        // Contagem de prioridades
        setPriorities(calculatePriority(objData, dataPriorityMs));
    
        // Contagem de "CAF's Expeditas"
        const issuedCount = calculateIssuedCAF(objData);
    
        // Atualizando os estados
        setvolumes(awbCount);
        setIssuedCAF(issuedCount);
    }, [objData, dataPriorityMs]);
    

    return (
        <div className="footerSheet">
            <div className="volumefooter">
                <span>VOLUMES EM CAF: {volumes}</span>
            </div>
            <div className="Madefooter">
                <span>CAF’S FEITAS: {madeCAF}</span>
            </div>
            <div className="auditedfooter">
                <span>CAF’S EXPEDIDAS: {issuedCAF}</span>
            </div>
            <div className="issuedfooter">
                <span>PRIORIDADES: {priorities}</span>
            </div>
        </div>
    )
}