import domtoimage from 'dom-to-image';

export const captureDiv = () => {
    const element = document.querySelector('.captureDiv');

    if(element === null) return null
    
    const originalWidth = element.style.width;

    element.style.width = '1280px';

    // Espera um tempo para garantir que o conteúdo tenha sido renderizado
    setTimeout(() => {
        domtoimage.toPng(element)
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'Relatório Final.png';
                link.click();
            })
            .catch((error) => {
                console.error('Oops, algo deu errado!', error);
            })
            .finally(() => {
                element.style.width = originalWidth;
            });
    }, 200); // Ajuste o tempo conforme necessário
};

