import { useContext, useEffect, useState } from "react";
import { FinalRelationshipContext } from "../../../context/Context";
import { IoAlertCircle } from "react-icons/io5";
import ItensList from "./ItensList";

const updateRota = (items) => {
    return items.map((obj) => {
        // Separar a string da rota
        const rotaParts = obj.Rota.split("-");
        // Verificar se o índice 1 é igual a "FOR"
        if (rotaParts[1] !== "FOR") {
            // Se for, apagar o objeto inteiro (ou, se preferir, retornar null)
            return null;
        } else {
            // Caso contrário, pegar o índice 4 e atualizar a chave "Rota"
            const updatedRota = rotaParts[4].replace(/[\[\]]/g, ''); // Remove colchetes
            return {
                ...obj,
                Rota: updatedRota // Atualiza a chave "Rota" com o valor sem colchetes
            };
        }
    }).filter(item => item !== null); // Filtra os itens nulos (removidos)
};

const categorizeDescription = (descriptions) => {
    if (typeof descriptions !== 'string') return "default"; // Verificar se 'descriptions' é uma string

    const atTheBase = ["DESCARREGADO", "COLETA RECEBIDA", "TRANSFERENCIA PARA", "EMBARQUE RECEBIDO", "RETIRADO DA CAF ANTERIOR", "RECEBIDO CD DE"];
    const inCAF = ["EM ROTA"];
    const justLeft = ["PROCESSO DE ENTREGA", "EM TRANSFERÊNCIA SECUNDÁRIA"];
    const inStreet = [
        "CLIENTE AUSENTE/ESTABELECIMENTO FECHADO", "DESTINATARIO DESCONHECIDO", "ENDERECO DESTINATARIO NAO LOCALIZADO", "ENTREGA REALIZADA",
        "ESPERA SUPERIOR A 20 MINUTOS", "FALTA NUMERO APT/CASA", "FORA DE ROTA", "VEICULO ENTREGADOR AVARIADO"
    ];
    const inNC = ["EMBALAGEM EM ANALISE", "DEVOLUÇÃO POR NC"];

    if (atTheBase.some(item => descriptions.startsWith(item))) {
        return "atTheBase";
    } else if (inCAF.some(item => descriptions.startsWith(item))) {
        return "inCAF";
    } else if (justLeft.some(item => descriptions.startsWith(item))) {
        return "justLeft";
    } else if (inStreet.some(item => descriptions.startsWith(item))) {
        return "inStreet";
    } else if (inNC.some(item => descriptions.startsWith(item))) {
        return "inNC";
    } else {
        return "default";
    }
};

const updateDescriptions = (dados) => {
    return dados.map(item => {
        const categoria = categorizeDescription(item.Descrição);
        return {
            ...item,
            Descrição: categoria  // Alterando a descrição para o nome da variável
        };
    });
};

function groupByID(dados) {
    // Passo 1: Agrupar os dados pelo ID
    const grupos = dados.reduce((acc, item) => {
        if (!acc[item.ID]) {
            acc[item.ID] = {
                AWB: new Set(),
                Rota: [],
                Descrição: [],
                Data: [], // Use array para permitir valores duplicados
                Nome: item.Nome,
                ID: item.ID
            };
        }

        // Passo 2: Adicionar os dados nos campos correspondentes
        acc[item.ID].AWB.add(item.AWB);
        acc[item.ID].Rota.push(item.Rota);
        acc[item.ID].Descrição.push(item.Descrição);
        if (item.Data) acc[item.ID].Data.push(item.Data); // Armazena todas as datas, mesmo duplicadas

        return acc;
    }, {});

    // Passo 3: Processar cada grupo para definir os valores finais
    const resultado = Object.values(grupos).map(grupo => {
        // Contar a quantidade de ocorrências de Rota e Descrição
        const contarOcorrencias = (arr) => {
            return arr.reduce((acc, valor) => {
                acc[valor] = (acc[valor] || 0) + 1;
                return acc;
            }, {});
        };

        const contagemRota = contarOcorrencias(grupo.Rota);
        const contagemDescricao = contarOcorrencias(grupo.Descrição);

        // Função para escolher o valor com mais ocorrências ou o primeiro em caso de empate
        const escolherValor = (contagem) => {
            let maiorQuantidade = 0;
            let valorEscolhido = null;
            for (let [valor, quantidade] of Object.entries(contagem)) {
                if (quantidade > maiorQuantidade) {
                    maiorQuantidade = quantidade;
                    valorEscolhido = valor;
                }
            }
            return valorEscolhido;
        };

        // Passo 4: Escolher Rota e Descrição com base na contagem
        const rotaEscolhida = escolherValor(contagemRota);
        const descricaoEscolhida = escolherValor(contagemDescricao);

        // Retornar o resultado final para cada grupo
        return {
            [grupo.ID]: {
                AWB: Array.from(grupo.AWB), // Convertendo Set para array
                Rota: rotaEscolhida,
                ID: grupo.ID,
                description: descricaoEscolhida,
                Data: grupo.Data, // Retorna todas as datas, incluindo duplicadas
                Nome: grupo.Nome
            }
        };
    });

    return resultado;
}

function alphabeticalOrdering(data) {
    if (!data || data.length === 0) return []; // Verifica se a entrada está vazia

    const regexZeroNumLetter = /^0(\d+[A-Z])$/; // 01A, 06B
    const regexNumLetterNum = /^(\d+[A-Z])(\d+)$/; // 1B1, 8C1
    const regexTwoLettersNum = /^([A-Z]{2}\d+)$/; // CA1, MA1
    const regexLetterTwoNums = /^([A-Z]\d{2})$/; // V01, S10

    const getNormalizedValue = (router) => {
        if (regexZeroNumLetter.test(router)) {
            return router.replace(/^0/, ''); // Remove leading 0
        } else if (regexNumLetterNum.test(router)) {
            return router.replace(/\d+$/, ''); // Remove last digit for comparison
        } else if (regexTwoLettersNum.test(router)) {
            return router; // No change needed
        } else if (regexLetterTwoNums.test(router)) {
            return router; // No change needed
        }
        return router; // Default case
    };

    const getLastNumber = (router) => {
        const match = router.match(/\d+$/); // Extract last number
        return match ? parseInt(match[0], 10) : 0;
    };

    function compareRouter(a, b) {
        const normA = getNormalizedValue(a.Rota);
        const normB = getNormalizedValue(b.Rota);


        if (regexTwoLettersNum.test(a.Rota) && regexTwoLettersNum.test(b.Rota)) {
            // Compare two letters followed by a number
            return a.Rota.localeCompare(b.Rota);
        } else if (regexLetterTwoNums.test(a.Rota) && regexLetterTwoNums.test(b.Rota)) {
            // Compare letter followed by two numbers
            return a.Rota.localeCompare(b.Rota);
        } else if (regexNumLetterNum.test(a.Rota) && regexNumLetterNum.test(b.Rota)) {
            // Compare based on normalized values and then by the last number
            const normalizedComparison = normA.localeCompare(normB);
            if (normalizedComparison === 0) {
                return getLastNumber(a.Rota) - getLastNumber(b.Rota);
            }
            return normalizedComparison;
        } else {
            // Normalize and compare the routers based on their normalized values
            return normA.localeCompare(normB);
        }
    }

    // Extraímos os valores dos objetos dentro do array
    const valuesArray = data.map(item => Object.values(item)[0]);

    // Ordena os objetos pelo campo `Rota`
    valuesArray.sort(compareRouter);

    return valuesArray;
}

function withoutCAF(itens){
    return itens.filter(item => item.ID !== "0")
}

const simplifyRoutes = (data) => {
    // Definir mapeamento de rotas
    const routeGroups = {
        'S': { 
            name: 'Serra', 
            routes: ['S']
        },
        'V': { 
            name: 'Vale', 
            routes: ['V']
        },
        'WP': { 
            name: 'Oeste P', 
            routes: ['WP']
        },
        'WI': { 
            name: 'Oeste I', 
            routes: ['WI']
        },
        'Qx': { 
            name: 'QXD', 
            routes: ['QX']
        },
        'L': { 
            name: 'Leste', 
            routes: ['L'],
            exclude: ['L01']
        }
    };

    // Função para determinar o grupo de uma rota
    const getRouteGroup = (route) => {
        for (const [key, group] of Object.entries(routeGroups)) {
            if (group.routes.some(r => route.startsWith(r)) && 
                (!group.exclude || !group.exclude.includes(route))) {
                return key;
            }
        }
        return null;
    };

    // Clonar o array original para não modificar o original
    const processedData = [...data];

    // Grupos que serão agrupados
    const groupedRoutes = {};

    // Separar itens para agrupamento e itens para manter
    const itemsToGroup = processedData.filter(item => {
        const groupKey = getRouteGroup(item.Rota);
        return groupKey !== null;
    });

    const itemsToKeep = processedData.filter(item => {
        const groupKey = getRouteGroup(item.Rota);
        return groupKey === null;
    });

    itemsToGroup.forEach(item => {
        const groupKey = getRouteGroup(item.Rota);   
        if (groupKey) {
            if (!groupedRoutes[groupKey]) {
                groupedRoutes[groupKey] = {
                    AWB: [],
                    Rota: `@${routeGroups[groupKey].name}`,
                    ID: routeGroups[groupKey].name,
                    description: null,
                    Data: [],
                    Nome: `Agregado ${routeGroups[groupKey].name}`, // Inicializando Nome vazio
                    IDs: [] // Adicionando um campo para armazenar os IDs
                };
            }
    
            // Juntar AWBs (não vai ser alterado)
            groupedRoutes[groupKey].AWB.push(...item.AWB);
    
            // Juntar Datas
            groupedRoutes[groupKey].Data.push(...(item.Data || []));
    
            // Determinar descrição predominante
            const currentDescription = groupedRoutes[groupKey].description;
            const newDescription = item.description;
            if (!currentDescription || 
                (newDescription === 'inCAF' && currentDescription !== 'inCAF')) {
                groupedRoutes[groupKey].description = newDescription;
            }
    
            // Adicionando o ID ao campo 'IDs'
            groupedRoutes[groupKey].IDs.push(item.ID);
        }
    });
    
    // Agora vamos contar quantas vezes o ID aparece em cada grupo e atualizar o Nome
    function toOrdinal(number) {
        return number + "°";  // Retorna o número seguido de "°"
    }
    
    Object.values(groupedRoutes).forEach((group, index) => {
        // Contar a quantidade de vezes que cada ID aparece no grupo
        const uniqueIDs = [...new Set(group.IDs)]; // IDs únicos no grupo
        const count = uniqueIDs.length; // Contagem dos IDs únicos
        const ordinalIndex = toOrdinal(index + 1); // Convertendo a posição do grupo para ordinal (1°, 2°, 3°, ...)
    
        // Atualizando o Nome com o número ordinal e a quantidade de IDs únicos
        group.ID = `${ordinalIndex} CAF's: ${count}`;
    });
    
    // Combinar itens agrupados com itens não agrupados 
    return [...itemsToKeep, ...Object.values(groupedRoutes)];
    
}

const filterItemsByAWBCount = (items, simpVariable) => {
    return items.filter(item => item.AWB.length - 1 >= simpVariable);
};

const filterCheckedEnt = (items) => { 
    return items.filter(item => item.description !== "inStreet");
};
const filterItemsByBrave = (items) => { 
    return items.filter(item => item.description !== "justLeft");
};

export default function CreateList() {
    const { processedData, objData, setObjData, dataPriority, checkedSimp, simpVariable, checkedEnt, checkedBrave } = useContext(FinalRelationshipContext);

    const [dataPriorityMs, setDataPriorityMs] = useState();
    
   useEffect(() => {
        setDataPriorityMs(setDate(dataPriority))
    }, [dataPriority])
    
    const setDate = (dataPriority) => {
        if (dataPriority) {
            try {
                const [day, month, year] = dataPriority.split("/").map(Number);

                if (!day || !month || !year) {
                    return new Date().setHours(0, 0, 0, 0);
                }

                const date = new Date(year, month - 1, day);
                if (isNaN(date.getTime())) {
                    return new Date().setHours(0, 0, 0, 0);
                }

                return date.setHours(0, 0, 0, 0);
            } catch (error) {
                return new Date().setHours(0, 0, 0, 0);
            }
        } else {
            return new Date().setHours(0, 0, 0, 0);
        }
    };

    useEffect(() => {
        let processed
    
        if (!processedData || processedData.length === 0) return;
    
        // Encontra o objeto com tipo "Complementos"
        const complementosObj = processedData.find(item => item.type === 'Complementos');
    
        // Se não houver "Complementos", retorna apenas o dado do primeiro objeto
        if (!complementosObj) {
            processed = processedData[0].data;
        } else {
            // Caso contrário, junta os dados dos dois
            const dadosObj = processedData.find(item => item.type === 'Dados');
            processed = [...(dadosObj?.data || []), ...complementosObj.data];
        }
        
        const routerSet = updateRota(processed)
        
        const descriptions = updateDescriptions(routerSet)
        const objData = groupByID(descriptions)
        const alphabeticalOrderingOrder = alphabeticalOrdering(objData)
        const removeItemsWithoutCAF = withoutCAF(alphabeticalOrderingOrder)
    
        // Adicionar lógica de simplificação
        const finalCheck = !checkedEnt ? filterCheckedEnt(removeItemsWithoutCAF) : removeItemsWithoutCAF
        const finalData = checkedSimp ? simplifyRoutes(finalCheck) : finalCheck;
        const finalDataSimpVariable = checkedSimp ? filterItemsByAWBCount(finalData, simpVariable) : finalData;
        const dinalCheckBrave = checkedBrave ? finalDataSimpVariable : filterItemsByBrave(finalDataSimpVariable)
        
    
        setObjData(dinalCheckBrave)          
        
    }, [processedData, setObjData, checkedSimp, simpVariable, checkedEnt, checkedBrave])

    return (
        <div className="mainSheet">
            <div className="headerMainSheet">
                <div className="bcRouter">
                    <span>ROTA</span>
                </div>
                <div className="bcAggregate">
                    <span>AGREGADO</span>
                </div>
                <div className="bcCAF">
                    <span>CAF</span>
                </div>
                <div className="bcAmount">
                    <span>QTD</span>
                </div>
                <div className="bcState">
                    <span>STATUS</span>
                </div>
                <div className="bcSituation">
                    <span>PRIORIDADE</span>
                </div>
            </div>
            <ItensList data={objData} today={dataPriorityMs} />
        </div>
    )
}