import "./styles.scss";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  const handleNavigationPartsReport = () => {
    navigate('/ReportParts');
  };

  const handleNavigationPlanning = () => {
    navigate('/Planning');
  };
  const handleNavigationFinalRelationship = () => {
    navigate('/FinalRelationship');
  };

  return (
    <main className="mainHome">
      <button onClick={handleNavigationPartsReport}>Relatório de Peças</button>
      <button onClick={handleNavigationFinalRelationship}>Relatório Finla</button>
    </main>
  )
}

export default Home